import { createWebHistory, createRouter } from "vue-router";

// Pages 
import LoginComponent from '@/views/auth/LoginPage'
import IndexPage from '@/views/home/IndexPage'

import ContractsCreatePage from '@/views/contracts/ContractsCreatePage'
import ContractsShowPage from '@/views/contracts/ContractsShowPage'

import InvoicesIndexPage from '@/views/invoices/InvoicesIndexPage'
import InvoicesShowPage from '@/views/invoices/InvoicesShowPage'

import IndexClientsPage from '@/views/clients/IndexClientsPage'
import CreateClientsPage from '@/views/clients/CreateClientsPage'
import ShowClientsPage from '@/views/clients/ShowClientsPage'

import LibrariesEditPage from '@/views/libraries/LibrariesEditPage'

import CitiesIndexPage from '@/views/cities/CitiesIndexPage'
import CitiesCreatePage from '@/views/cities/CitiesCreatePage'

import LogsIndexPage from '@/views/logs/LogsIndexPage'

import NotificationCreatePage from '@/views/notifications/NotificationCreatePage'

import CategoriesIndexPage from "@/views/Categories/CategoriesIndexPage.vue";
import CategoriesCreatePage from "@/views/Categories/CategoriesCreatePage.vue";

import NotFound from '@/views/customs/NotFoundPage'

const routes = [
   { path: '/', name: 'login', component: LoginComponent },
   
   { path: '/home', name: 'home', component: IndexPage },

   { path: '/contracts/:id/create', name: 'contracts-create', component: ContractsCreatePage },
   { path: '/contracts/:id/show', name: 'contracts-show', component: ContractsShowPage },
   
   { path: '/invoices', name: 'invoices', component: InvoicesIndexPage },
   { path: '/invoices/:id/show', name: 'invoices-show', component: InvoicesShowPage },

   { path: '/clients', name: 'clients-index', component: IndexClientsPage },
   { path: '/clients/create', name: 'clients-create', component: CreateClientsPage },
   { path: '/clients/:id/show', name: 'clients-show', component: ShowClientsPage },

   { path: '/libraries/:id/edit', name: 'libraries-edit', component: LibrariesEditPage },

   { path: '/cities', name: 'cities-index', component: CitiesIndexPage },
   { path: '/cities/create', name: 'cities-create', component: CitiesCreatePage },
   
   { path: '/logs', name: 'logs', component: LogsIndexPage },

   { path: '/notifications/:id', name: 'notifications', component: NotificationCreatePage },

   { path: '/categories/dewey', name: 'categories-dewey-index', component: CategoriesIndexPage },
   { path: '/categories/dewey/create', name: 'categories-dewey-create', component: CategoriesCreatePage },

   { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});
 
export default router;