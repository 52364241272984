<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <div class="row mb-4">
               <div class="col md-4">
                  <PageTitleComponent title="Detalhes da Fatura" />
               </div>
               <div v-if="invoice.status === 1" class="col-md-8 mt-3 text-end">
                  <div class="d-flex gap-3 justify-content-end">
                     <button 
                        data-bs-toggle="modal" 
                        data-bs-target="#modalChargeId" 
                        class="btn btn-sm btn-secondary rounded-3 px-3">
                        Editar
                     </button>
                     <button
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        class="btn btn-sm btn-primary rounded-3 px-3 me-3">
                        Alterar Situação
                     </button>
                  </div>
               </div>
            </div>

            <div v-if="invoice.status === 3">
               <div class="alert alert-danger" role="alert">
                  Contrato cancelado: {{ invoice.reason_status }}
               </div>
            </div>

            <div class="row">
               <div class="col-md-6">
                  <div class="row">
                     <div class="col-12 mb-4">
                        <div class="card bg-dark p-3">
                           <h5>Dados do Pagador</h5>
                           <div class="mb-3">
                              <div>Contratante: {{ invoice.library?.name }}</div>
                           </div>

                           <div class="mb-3">
                              Documento: {{ invoice.library?.document ?? 'Sem documento' }}
                           </div>

                           <div class="mb-3">
                              E-mail: {{ invoice.user?.email }}
                           </div>

                           <div class="mb-3">
                              Telefone: {{ invoice.user?.phone }}
                           </div>

                        </div>
                     </div>

                     <div class="col-12 mb-5">
                        <div class="card bg-dark p-3">
                           <h5 class="mb-3">Limites contratados</h5>
                           <div class="mb-3">
                              <div>Títulos: {{ invoice.contract?.books_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Leitores: {{ invoice.contract?.readers_limit }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Empréstimos: {{ invoice.contract?.loans_limit ?? 'Sem limite' }}</div>
                           </div>
                           <div class="mb-3">
                              <div>Colaboradores: {{ invoice.contract?.collaborators_limit }}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
               <!-- Detalhes -->
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3 mb-4">
                     <div class="d-flex justify-content-between align-items-center">
                        <h5 class="mb-0">Referência: {{ invoice.month + '/' + invoice.year }}</h5>
                        <div class="">
                           <span v-if="invoice.status == 1" class="badge bg-warning text-dark py-2 px-3">Pendente</span>
                           <span v-else-if="invoice.status == 2" class="badge bg-success py-2 px-3">Pago</span>
                           <span v-else-if="invoice.status == 3" class="badge bg-danger py-2 px-3">Cancelado</span>
                        </div>
                     </div>
                  </div>

                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Detalhes</h5>
                     
                     <div class="d-flex justify-content-between">
                        <div class="mb-3">
                           <div>ID Interno: {{ invoice.id }}</div>
                        </div>
                        <div class="mb-3">
                           <div>ID Externo: {{ invoice.external_id ?? 'Não informado' }}</div>
                        </div>
                     </div>

                     <div class="mb-3">
                        Data de Vencimento: {{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}
                     </div>

                     <div class="mb-3">
                        Data do Pagamento: {{ (invoice.date_paid) ? $dayjs(invoice.date_paid).format("DD/MM/YYYY") : 'Não informado' }}
                     </div>

                     <div class="mb-3">
                        Link de pagamento: 
                        <a :href="invoice.payment_link" target="_blank" class="ms-2" rel="noopener noreferrer">
                           Ver Boleto
                        </a>
                     </div>

                     <div class="mb-3">
                        <div>Plano: {{ invoice.contract?.plan?.name }}</div>
                     </div>

                     <div class="mb-3">
                        Valor original: R$ {{ invoice.amount }}
                     </div>

                     <div class="mb-3">
                        Descontos: 
                        <span v-if="invoice.partner_fee">R$ {{ invoice.partner_fee }}</span>
                        <span v-else>R$ 0,00</span>
                     </div>

                     <div class="mb-3">
                        Valor final: R$ {{ invoice.total }}
                     </div>

                     <div class="mb-3">
                        Criada em: {{ $dayjs(invoice.created_at).format("DD/MM/YYYY HH:MM") }}
                     </div>
                  </div>
               </div>

            </div>

         </div>
      </div>

      <!-- Modal add link de pagamento -->
      <div class="modal fade" id="modalChargeId" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalChargeIdLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="modalChargeIdLabel">Vincular a Clientbase</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label>Link da Fatura</label>
                     <textarea v-model="formUpdate.payment_link" type="text" class="form-control"></textarea>
                  </div>
                  <div class="mb-3">
                     <label class="form-label">Vencimento</label>
                     <input v-model="formUpdate.due_date" type="date" class="form-control">
                  </div>
                  <div class="mb-3">
                     <label class="form-label">ID Externo</label>
                     <input v-model="formUpdate.external_id" class="form-control">
                  </div>
                  <div class="mb-3">
                     <label class="form-label">NFS-e</label>
                     <input v-model="formUpdate.nfse" class="form-control">
                  </div>
                  <div class="mb-3">
                     <label class="form-label">Anotação (cliente pode ver)</label>
                     <textarea v-model="formUpdate.annotation" class="form-control" rows="2"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="updateInvoice" data-bs-dismiss="modal" type="button" class="btn btn-danger">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal alterar situação -->
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
               <h5 class="modal-title" id="staticBackdropLabel">Alterar situação da cobrança</h5>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-3">
                     <label>Situação</label>
                     <select v-model="formStatus.status" class="form-select">
                        <option v-for="status in paymentStatus"
                           :key="status.code"
                           :value="status.code">
                           {{ status.name }}
                        </option>
                     </select>
                  </div>
                  <div v-if="formStatus.status == 2" class="mb-3">
                     <label>Data de Pagamento</label>
                     <input v-model="formStatus.date_paid" type="date" class="form-control">
                  </div>
                  <div v-if="formStatus.status == 3" class="mb-3">
                     <label>Motivo do cancelamento</label>
                     <textarea v-model="formStatus.annotation" type="text" class="form-control"></textarea>
                  </div>
               </div>
               <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               <button @click="changePaymentStatus" data-bs-dismiss="modal" type="button" class="btn btn-primary">Confirmar</button>
               </div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';

export default {
   name: "ContractsShowPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
},

   data() {
      return {
         invoice: {},
         formStatus: {
            status: null,
            annotation: null,
            date_paid: null,
         },
         formUpdate: {
            payment_link: '',
            due_date: '',
            annotation: '',
            external_id: '',
            nfse: ''   
         }
      }
   },

   mounted() {
      this.fetchInvoice();
   },

   computed: {
      paymentStatus() {
        return ConstantsApp.PaymentStatus;
      }
   },

   methods: {
      async fetchInvoice() {
         let loader = this.$loading.show();
         try {
            const response = await this.$axios.get(`master/invoices/${this.$route.params.id}`);
            this.invoice = response.data;
            this.formStatus = {
               status: response.data.status,
               annotation: response.data.annotation,
               date_paid: response.data.date_paid,
            };
            
            this.formUpdate = {
               payment_link: response.data.payment_link,
               due_date: response.data.due_date,
               annotation: response.data.annotation,
               external_id: response.data.external_id,
               nfse: response.data.nfse
            }
            
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data?.message ?? 'Contrato não localizado', type: 'error'});
         }
         loader.hide();
      },
      
      async changePaymentStatus() {
         if (this.formStatus.status == this.invoice.status) {
            return;
         }
         
         let loader = this.$loading.show();
         
         try {
            await this.$axios.put(`master/invoices/${this.$route.params.id}/payment-status`, this.formStatus);
            this.$notify({title: 'Cancelado com sucesso', type: 'success'});
            this.fetchInvoice();
         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data?.message ?? 'Erro', type: 'error'});
         }
         
         loader.hide();
      },

      async updateInvoice() {
         let loader = this.$loading.show();

         try {
            const response = await this.$axios.put(`master/invoices/${this.$route.params.id}`, this.formUpdate);
            this.$notify({
               title: response.message, 
               type: 'success'
            });
            
            this.findContract();

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }

         loader.hide();
      },
   },
}
</script>
